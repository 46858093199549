const vectrexHeadingStyles = {
  fontWeight: 700,
  color: 'text',
  my: 2,
};

const marthaHeadingStyles = {
  fontFamily: 'body',
  fontWeight: 700,
  color: 'text',
  mt: 3,
  mb: 2,
};

export default {
  fontSizes: [14, 16, 18, 24, 36, 48, 60, 65, 144],
  fonts: {
    body: 'Martha, Courrier, monospace',
    heading: 'Vectrex, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    body: {
      fontFamily: 'body',
      color: 'text',
      fontSize: 1,
      lineHeight: 1.5,
    },
    h1: {
      ...vectrexHeadingStyles,
      fontFamily: 'heading',
      fontSize: [5, 6, 7],
    },
    h2: {
      ...vectrexHeadingStyles,
      fontFamily: 'heading',
      fontSize: [4, 4, 5],
    },
    h3: {
      ...marthaHeadingStyles,
      fontSize: [3, 3, 4],
    },
    h4: {
      ...marthaHeadingStyles,
      fontSize: 3,
    },
    h5: {
      ...marthaHeadingStyles,
      fontSize: 2,
    },
  },
};
