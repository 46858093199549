import text from './text';

const baseButtonStyles = {
  fontFamily: 'body',
  borderRadius: '4px',
  lineHeight: '32px',
  maxWidth: 'auto',
  cursor: 'pointer',
  transition: 'transform 0.125s',
  ':hover': {
    transform: 'scale(1.05)',
  },
  ':focus': {
    outline: 'none',
    boxShadow: theme =>
      `0 0 0 2px ${theme.colors.background}, 0 0 0 6px ${theme.colors.text}`,
  },
  ':active': {
    transform: 'scale(0.95)',
  },
};

const dayOfWeekColorMode = () => {
  const today = new Date();
  switch (today.getDay()) {
    case 1:
    case 2:
      return {
        light: { text: '#002E3B', background: '#6EFF96' },
        dark: { text: '#6EFF96', background: '#002E3B' },
      };
    case 3:
      return {
        light: { text: '#000', background: '#FF6464' },
        dark: { text: '#FF6464', background: '#000' },
      };
    case 4:
      return {
        light: { text: '#230055', background: '#f0f' },
        dark: { text: '#f0f', background: '#230055' },
      };
    case 5:
      return {
        light: { text: '#002E3B', background: '#6EFF96' },
        dark: { text: '#6EFF96', background: '#002E3B' },
      };
    case 6:
    case 0:
      return {
        light: { text: '#000', background: '#FF6464' },
        dark: { text: '#FF6464', background: '#000' },
      };
  }
};

export default {
  useColorSchemeMediaQuery: true,
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    red: '#FF6464',
    marine: '#002E3B',
    purple: '#230055',
    green: '#6EFF96',
    pink: '#f0f',
    blue: '#0ff',
    black: '#000',
    white: '#fff',
    //----aliases for the theme----
    primary: '#FF6464',
    secondary: '#230055',
    text: '#230055',
    background: '#FF6464',
    modes: { ...dayOfWeekColorMode() },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    link: {
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 700,
      color: 'text',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: theme =>
          `0 0 0 2px ${theme.colors.background}, 0 0 0 4px ${theme.colors.text}`,
        borderRadius: '2px',
      },
    },
    navlink: {
      fontFamily: 'body',
      fontSize: 2,
      color: 'text',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: theme =>
          `0 0 0 2px ${theme.colors.background}, 0 0 0 4px ${theme.colors.text}`,
        borderRadius: '2px',
      },
    },
  },
  ...text,
  buttons: {
    primary: {
      color: 'background',
      bg: 'text',
      ...baseButtonStyles,
    },
    secondary: {
      color: 'text',
      bg: 'background',
      borderStyle: 'solid',
      borderColor: 'text',
      borderWidth: '1px',
      ...baseButtonStyles,
    },
  },
  brushstroke: {
    normal: {
      color: 'primary',
      'svg path': {
        fill: 'secondary',
      },
    },
    inverse: {
      color: 'secondary',
      'svg path': {
        fill: 'primary',
      },
    },
  },
};
