/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui';
import React from 'react';
import { Box, Button, Flex, Link as Anchor } from 'rebass';
import { motion, AnimatePresence, useReducedMotion } from 'framer-motion';
import { LocalizedLink as Link } from './LocalizedLink';
import { useTranslation } from '../i18n/TranslationContext';
import { useLocale } from '../i18n/LocaleContext';
import { useHeaderLinks } from './default-header-links';
import Logo from './Logo';

const HOME_TAB_WIDTH = 165;
const TAB_WIDTH = 40;

const NavLink: React.FC<{ to: string; isActive: boolean }> = ({
  children,
  to,
  isActive,
}) => (
  <Box
    sx={{
      pointerEvents: 'all',
      backgroundColor: 'background',
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid',
      borderRightColor: isActive ? 'text' : 'transparent',
      borderLeftColor: 'text',
      borderTopColor: 'transparent',
      borderTop: 'none',
      borderBottom: 'none',
      a: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '96px',
        minWidth: `${TAB_WIDTH}px`,
        color: 'text',
        fontFamily: 'body',
        fontWeight: isActive ? '700' : '400',
        writingMode: 'vertical-rl',
        textDecoration: 'none',
        '&:focus': {
          outline: 'none',
          boxShadow: theme =>
            `0 0 0 -2px ${theme.colors.background}, 0 0 0 2px ${theme.colors.text}`,
        },
      },
    }}
  >
    <Link to={to}>{children}</Link>
  </Box>
);

const Spacer = () => {
  const reduceMotion = useReducedMotion();
  const transition = reduceMotion ? { duration: 0 } : { delay: 0.3 };
  return (
    <motion.div
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      transition={transition}
      variants={{
        open: { flex: 1, opacity: 1 },
        collapsed: { flex: 0, opacity: 0 },
      }}
    />
  );
};

const ThemeSwitchLink: React.FC<{
  isColorMode: string;
}> = ({ children, isColorMode }) => {
  const [mode, setMode] = useColorMode();

  return (
    <Button
      sx={{
        display: 'block',
        width: '100%',
        height: 'auto',
        py: 0,
        fontSize: 1,
        fontFamily: 'body',
        border: 'none',
        borderRadius: 0,
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontWeight: mode === isColorMode ? '700' : '400',
        textDecoration: mode === isColorMode ? 'underline' : 'none',
        borderBottom: isColorMode === 'dark' ? 'solid 1px' : 'none',
        color: 'text',
        cursor: 'pointer',
        '&:hover': {
          color: 'background',
          backgroundColor: 'text',
        },
      }}
      onClick={e => {
        setMode(mode === 'light' ? 'dark' : 'light');
      }}
    >
      {children}
    </Button>
  );
};

const ThemeSwitch = () => {
  const t = useTranslation();
  return (
    <Flex
      aria-hidden="true"
      flexDirection="row"
      alignItems="center"
      sx={{
        width: 'calc(100% + 2px)',
        borderTop: '1px solid',
        borderLeft: '1px solid',
        borderBottom: 'none',
        borderColor: 'text',
        fontFamily: 'body',
      }}
    >
      <Flex
        px={1}
        alignItems="center"
        sx={{
          textAlign: 'center',
          borderRight: '1px solid',
          borderColor: 'text',
          height: '100%',
          fontWeight: 'bold',
        }}
      >
        {t('nav_color_mode')}
      </Flex>
      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        <ThemeSwitchLink isColorMode="dark">{t('nav_dark')}</ThemeSwitchLink>
        <ThemeSwitchLink isColorMode="light">{t('nav_light')}</ThemeSwitchLink>
      </Box>
    </Flex>
  );
};

const MainTab: React.FC<{ to: string; isActive: boolean }> = ({
  to,
  isActive,
}) => {
  const t = useTranslation();
  return (
    <aside
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pointerEvents: 'all',
        width: `${HOME_TAB_WIDTH}px`,
        backgroundColor: 'background',
      }}
    >
      <Link
        sx={{
          display: 'inline-block',
          width: '92px',
          '&:focus': {
            outline: 'none',
            boxShadow: theme =>
              `0 0 0 2px ${theme.colors.background}, 0 0 0 4px ${theme.colors.text}`,
          },
        }}
        to={to}
        aria-label={t('nav_homepage')}
        title={t('nav_homepage')}
      >
        <Logo
          fill="text"
          sx={{
            width: '100%',
          }}
          type="21interaction-21glitch"
          aria-hidden={true}
        />
      </Link>
      <Button
        variant="secondary"
        as="a"
        href="https://shop.ixda.org/"
        target="blank"
        sx={{
          width: 'calc(100% + 2px)',
          borderRadius: 0,
          position: 'relative',
          right: '0',
        }}
      >
        {t('nav_get_merch')}
      </Button>
      <ThemeSwitch />
    </aside>
  );
};

const mapper: Record<string, string> = {
  team: 'about',
};

function trimTrailingSlash(s: string) {
  const splitString = s.split('/');
  if (splitString.slice(-1).pop() === '') {
    return splitString.slice(0, -1).join('/');
  }

  return s;
}
const DesktopNavigation: React.FC = () => {
  const links = useHeaderLinks();
  const { basePath } = useLocale();
  // @TODO: this some ghetto stuff.
  // There's a smarter way of checking if we at the index page.
  const isRoot = basePath === '/' || basePath === '/fr' || basePath === '/fr/';
  const isActivePageOffTheMenu = links.every(
    ({ path }) => basePath.indexOf(path) === -1,
  );

  return (
    <React.Fragment>
      <nav
        sx={{
          position: 'fixed',
          width: '100%',
          maxWidth: '2560px',
          height: 'calc(100vh - 72px)',
          pointerEvents: 'none',
          zIndex: 10,
          bottom: 0,
          display: ['none', 'none', 'flex'],
        }}
      >
        <MainTab to="/" isActive={isRoot} />
        <AnimatePresence initial={false}>
          {(isRoot || isActivePageOffTheMenu) && <Spacer />}
        </AnimatePresence>
        {links.map(({ path, label }) => {
          const isActive = trimTrailingSlash(basePath).includes(
            trimTrailingSlash(path),
          );

          return (
            <React.Fragment key={path}>
              <NavLink to={path} isActive={isActive}>
                {label}
              </NavLink>
              <AnimatePresence initial={false}>
                {isActive && <Spacer />}
              </AnimatePresence>
            </React.Fragment>
          );
        })}
      </nav>
    </React.Fragment>
  );
};

const getPadding = (index: number, total: number) => {
  const left = HOME_TAB_WIDTH + index * TAB_WIDTH;
  const right = (total - index - 1) * TAB_WIDTH;
  return {
    paddingLeft: [0, 0, `${left}px`],
    paddingRight: [0, 0, `${right}px`],
  };
};

const ElementRenderer: React.FC<{
  uri: string;
}> = ({ children }) => {
  const links = useHeaderLinks();
  const { basePath } = useLocale();
  const isRoot = basePath === '/' || basePath === '/fr' || basePath === '/fr/';
  const pageIndex = isRoot
    ? 0
    : links.findIndex(({ path }) => {
        return trimTrailingSlash(basePath).includes(trimTrailingSlash(path));
      }) + 1;
  const paddings = getPadding(pageIndex, links.length + 1);

  const reduceMotion = useReducedMotion();
  const transition = reduceMotion ? { duration: 0 } : { duration: 0.5 };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={basePath}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, overflowY: 'hidden' }}
        exit={{ opacity: 0, overflowY: 'hidden' }}
        transition={transition}
        sx={{
          ...paddings,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export { DesktopNavigation, ElementRenderer };
