import React from 'react';
import { Link } from 'gatsby';
import { useLocale } from '../i18n/LocaleContext';
// Use the globally available context to choose the right path
const LocalizedLink: React.FC<{ to: string }> = ({ to, ...props }) => {
  const { locale } = useLocale();
  const isIndex = to === `/`;

  const path = locale === 'en' ? to : `/${locale}${isIndex ? `` : `${to}`}`;
  return <Link {...props} to={path} />;
};

export { LocalizedLink };
