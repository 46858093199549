export const fr_lang_keys = {
  site_title: 'Interaction 21 • 31 janvier au 5 février 2021',
  meta_description:
    "En 2021, la Semaine d'Interaction était virtuelle pour la première vois, avec pour thème: Design in perilous times",
  ixd22_banner:
    "Un an plus tard, qu'avons-nous appris? Soyez des nôtres pour Interaction 22",
  nav_homepage: "Page d'accueil d'Interaction 21",
  nav_menu: 'MENU',
  nav_tickets_avail_soon: 'Billets disponibles bientôt',
  nav_get_tickets: 'Billeterie ↗',
  nav_get_merch: 'Boutique ↗',
  nav_close: 'FERMER',
  nav_color_mode: 'Mode',
  nav_dark: 'Foncé',
  nav_light: 'Clair',

  calgrid_jan: 'jan',
  calgrid_feb: 'fév',

  calgrid_ides_title: "Sommet de l'éducation",
  calgrid_ides_desc:
    "Faites croitre votre passion pour l'éducation, explorez comment l'instruction au design est encore plus transdisciplinaire.",

  calgrid_workshop_title: 'Ateliers',
  calgrid_workshop_desc:
    'Apprenez des meilleurs et mettez les mains à la pâte.',

  calgrid_sdc_title: 'Défi design étudiant',
  calgrid_sdc_desc:
    'Démontrez vos aptitudes dans cette charette design tout en apprenant avec des mentors.',

  calgrid_ixd21_title: 'Conférence Interaction 21',
  calgrid_ixd21_desc:
    "L'événement principal. Profitez de trois jours de contenu, livrés dans votre fuseau-horaire. Faites des rencontres uniques, du social en temps-réel, écoutez les experts, rejoignez les conversations qui changent nos relations avec nous-mêmes, nos objets, notre environnement et toutes la société.",

  calgrid_ll_title: 'Rassemblement leaders locaux',
  calgrid_ll_desc:
    "Votre communauté locale vous passionne? Joignez-nous pour un rassemblement d'une journée.",

  calgrid_awards_title: 'Cérémonie des Awards',
  calgrid_awards_desc:
    "La soirée annuelle des Interaction Awards célèbre les leaders et l'innovation autour du monde.",

  calgrid_edu_summit_more: "Plus sur le Sommet de l'éducation",
  calgrid_ixdaw_more: 'Plus sur la Cérémonie des Awards',
  calgrid_sdc_more: 'Plus sur le Défi design étudiant',
  calgrid_llr_more: 'Plus sur le Rassemblement leaders locaux',
  calgrid_workshop_schedule: "Horaire d'ateliers",
  calgrid_conf_schedule: 'Horaire de conférence',

  calgrid_advisory:
    '*Les dates affichées peuvent différer selon votre fuseau-horaire.',

  keynote_highlight_title: "Aperçu des têtes d'affiche 👀",
  keynote_highlight_kicker: 'Programme complet dévoilé le 16 novembre',

  about_heading: 'À propos',
  about_team_h2: "L'équipe d'Interaction 21",
  about_team_text:
    'Interaction 21 est possible grâce au dévouement de ces individus passionés.',

  speakers_title: 'Conférenciers',
  speakers_subtitle: 'Rencontrez des gens formidables',

  partners_heading: 'Nos partenaires',
  partners_title: 'Sans eux, nous ne serions pas ici. Un énorme merci à',
  partner_with_us_heading: 'Devenir partenaire',
  faq_heading: 'FAQ',
  speaker_faq_heading: 'FAQ des conférenciers',
  cfp_title: 'Appel aux propositions',
  program_heading: 'Programmation',
  program_announcement_date:
    'La pleine programmation sera annoncée le 16 novembre 2020.',
  program_times_shown_in: 'Heures en',
  program_time_format_calendar_day: 'H:mm',
  program_time_format_calendar: 'H:mm',
  program_time_format_session: 'DD MMM, H:mm',
  program_pick_a_stream: 'Choisir un flux',
  program_americas: 'Amériques',
  program_apac: 'Asie-Pacifique',
  program_emea: 'EMEA',
  coc_title: 'Code de conduite',
  privacy_title: 'Politique de confidentialité',
  accessibility_title: 'Accessibilité',
  footer_join_the_conversation: 'Contactez-nous',
  footer_get_involved: 'Devenir partenaire',
  footer_follow_the_movement: 'Suivez le mouvement',
  footer_sub_to_our_newsletter: 'Abonnez-vous à notre infolettre',
  footer_enter_your_email: 'Entrez votre adresse courriel',
  footer_subscribe_btn: 'Abonnez-vous à notre infolettre',
  footer_coc: 'Code de conduite',
  footer_privacy: 'Confidentialité',
  footer_copyright: 'Copyright 2004 > 2021 Interaction Design Association',
  footer_accessibility: 'Accessibilité',
  jump_to_content_button: 'Aller au contenu principal',
};
