import { useLocale } from '../i18n/LocaleContext';

const defaultHeaderLinks = [
  { path: '/about', label: { en: 'About', fr: 'À propos' } },
  //{ path: '/program', label: { en: 'Program', fr: 'Programme' } },
  //{ path: '/partner', label: { en: 'Partner', fr: 'Partenaires' } },
  //{ path: '/covid-faq', label: { en: 'FAQ', fr: 'FAQ' } },
  {
    path: '/program',
    label: { en: 'Program', fr: 'Programmation' },
  },
  {
    path: '/partners',
    label: { en: 'Partners', fr: 'Nos partenaires' },
  },
];

function useHeaderLinks() {
  const { locale } = useLocale();
  const headerLinks = defaultHeaderLinks.map(links => ({
    path: links.path,
    label: links.label[locale],
  }));

  return headerLinks;
}

export { defaultHeaderLinks, useHeaderLinks };
