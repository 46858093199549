/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react');
const { LocaleProvider } = require('./src/i18n/LocaleContext');
const { TranslationProvider } = require('./src/i18n/TranslationContext');
const {
  ElementRenderer,
  DesktopNavigation,
} = require('./src/components/DesktopNavigation');
const { IxD22Banner } = require('./src/components/IxD22Banner');
const { en_lang_keys } = require('./src/i18n/en.js');
const { fr_lang_keys } = require('./src/i18n/fr.js');

exports.wrapPageElement = ({ element, props }) => {
  const splitUri = (props.uri || window.location.pathname).split('/');
  const locale = splitUri[1] && splitUri[1] === 'fr' ? 'fr' : 'en';
  if (props.location.pathname.includes('/interstitial')) {
    return element;
  }
  return (
    <LocaleProvider
      locale={{
        locale,
        basePath: props.location.pathname.replace('/fr/', '/'),
        path: props.path,
      }}
    >
      <TranslationProvider
        i18n={{
          en: en_lang_keys,
          fr: fr_lang_keys,
        }}
      >
        <IxD22Banner />
        <div style={{ width: '100%', maxWidth: '2560px', margin: '0 auto ' }}>
          <DesktopNavigation />
          <ElementRenderer {...props}>{element}</ElementRenderer>
        </div>
      </TranslationProvider>
    </LocaleProvider>
  );
};
