/** @jsx jsx */
import { jsx } from 'theme-ui';

export const H1 = props => (
  <h1 sx={{ variant: 'text.h1', color: 'inherit' }} {...props} />
);

export const H2 = props => (
  <h2 sx={{ variant: 'text.h2', color: 'inherit' }} {...props} />
);

export const H3 = props => (
  <h3 sx={{ variant: 'text.h3', color: 'inherit' }} {...props} />
);

export const H4 = props => (
  <h4 sx={{ variant: 'text.h4', color: 'inherit' }} {...props} />
);

export const H5 = props => (
  <h5 sx={{ variant: 'text.h5', color: 'inherit' }} {...props} />
);

export const Text = props => (
  <p sx={{ variant: 'text.body', color: 'inherit' }} {...props} />
);

export const P = props => (
  <p
    sx={{ variant: 'text.body', color: 'inherit', marginBottom: [1, 3, 3] }}
    {...props}
  />
);

export const Strong = props => (
  <strong
    sx={{ variant: 'text.body', color: 'inherit', fontWeight: 'bold' }}
    {...props}
  />
);
