import React from 'react';

const LocaleContext = React.createContext('en');

export const LocaleProvider = ({ children, locale }) => (
  <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
);

export const useLocale = () => {
  return React.useContext(LocaleContext);
};
