/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { P } from './Text';
import { useTranslation } from '../i18n/TranslationContext';

const IxD22Banner = () => {
  const t = useTranslation();
  return (
    <P
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        width: '100%',
        height: '72px',
        px: 3,
        borderBottom: 'solid 1px',
        backgroundColor: '#000',
        color: '#fff',
      }}
    >
      <a
        href="https://interaction22.ixda.org"
        target="_blank"
        rel="noopener"
        sx={{ variant: 'variants.link', color: 'white' }}
      >
        {t('ixd22_banner')} ↗
      </a>
    </P>
  );
};

export { IxD22Banner };
