export const en_lang_keys = {
  site_title: 'Interaction 21 • 31 January to 5 February 2021',
  meta_description:
    'In 2021, Interaction week was a virtual one for the first time, bearing the theme: Design in perilous times',
  ixd22_banner: 'One year on, what have we learned? Join us for Interaction 22',
  nav_homepage: 'Interaction 21 Homepage',
  nav_menu: 'MENU',
  nav_tickets_avail_soon: 'Tickets available soon',
  nav_get_tickets: 'Get tickets ↗',
  nav_get_merch: 'Get merch ↗',
  nav_close: 'CLOSE',
  nav_color_mode: 'Mode',
  nav_dark: 'Dark',
  nav_light: 'Light',

  calgrid_ides_title: 'Interaction Design Education Summit',
  calgrid_ides_desc:
    'Grow your passion for learning and educating, explore how design learning is even more transdisciplinary.',

  calgrid_workshop_title: 'Workshops',
  calgrid_workshop_desc: 'Join the best designers and learn hands on.',

  calgrid_sdc_title: 'Student Design Charette',
  calgrid_sdc_desc:
    'Demonstrate your skills in the design charette and learn from amazing mentors.',

  calgrid_ixd21_title: 'Interaction 21 conference',
  calgrid_ixd21_desc:
    'The main event. Enjoy three days of content, delivered to you in your timezone. Complete with real time networking and unique opportunities to socialize, listen to experts and join conversations to reshape our changing relations with ourselves, our objects, the environment, and the society as a whole.',

  calgrid_ll_title: 'Local Leaders retreat',
  calgrid_ll_desc:
    'Are you passionate about growing your local community? Join us in a one day retreat.',

  calgrid_awards_title: 'Awards Ceremony',
  calgrid_awards_desc:
    'The annual Interaction Awards celebrate design thought leadership and innovation around the globe.',

  calgrid_edu_summit_more: 'More on the Education Summit',
  calgrid_ixdaw_more: 'More on the Interaction Awards',
  calgrid_sdc_more: 'More on the Student Design Charette',
  calgrid_llr_more: "More on the Local Leader's retreat",
  calgrid_workshop_schedule: 'Workshop schedule',
  calgrid_conf_schedule: 'Conference schedule',

  calgrid_advisory: '*Dates shown may vary based on your timezone.',

  keynote_highlight_title: 'Keynote speakers sneak peek 👀',
  keynote_highlight_kicker: 'Full program coming 16 November',

  about_heading: 'About',
  about_team_h2: 'The Interaction 21 team',
  about_team_text:
    'Interaction 21 is possible thanks to the dedication of these passionate individuals.',

  speakers_title: 'Speakers',
  speakers_subtitle: 'Connect with great people',

  partners_heading: 'Our partners',
  partners_title: 'Without them, we would not be here. A huge thank you to',
  partner_with_us_heading: 'Partner with us',
  faq_heading: 'FAQ',
  speaker_faq_heading: 'Speaker FAQ',
  cfp_title: 'Call for proposals',
  program_heading: 'Program',
  program_announcement_date:
    'Stay tuned for the full program, coming on 16 November 2020.',
  program_times_shown_in: 'Times in',
  program_time_format_calendar_day: 'H:mm',
  program_time_format_calendar: 'h:mm a',
  program_time_format_session: 'DD MMM, h:mm a',
  program_pick_a_stream: 'Pick a stream',
  program_americas: 'Americas',
  program_apac: 'Asia-Pacific',
  program_emea: 'EMEA',
  coc_title: 'Code of conduct',
  privacy_title: 'Privacy policy',
  accessibility_title: 'Accessibility',
  footer_join_the_conversation: 'Get in touch',
  footer_get_involved: 'Partner with us',
  footer_follow_the_movement: 'Follow the movement',
  footer_sub_to_our_newsletter: 'Subscribe to our newsletter',
  footer_enter_your_email: 'Enter your email',
  footer_subscribe_btn: 'Subscribe to our newsletter',
  footer_coc: 'Code of conduct',
  footer_privacy: 'Privacy',
  footer_copyright: 'Copyright 2004 > 2021 Interaction Design Association',
  footer_accessibility: 'Accessibility',
  jump_to_content_button: 'Skip to main content',
};
