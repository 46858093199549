import React from 'react';
import get from 'lodash.get';
import { useLocale } from './LocaleContext';

const TranslationContext = React.createContext({});

export const TranslationProvider = ({ children, i18n }) => (
  <TranslationContext.Provider value={i18n}>
    {children}
  </TranslationContext.Provider>
);

export const useTranslation = () => {
  const { locale } = useLocale();
  const i18n = React.useContext(TranslationContext);
  return path => {
    return get(i18n, `${locale}.${path}`);
  };
};
