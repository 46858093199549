/** @jsx jsx */
import { jsx, SxProps } from 'theme-ui';

type LogoType =
  | 'ixda'
  | '21glitch'
  | '21interaction-21glitch'
  | 'interaction-21glitch';

const logoMapping: Record<LogoType, string> = {
  ixda: '#IxDA-logo',
  '21glitch': '#21-glitch',
  '21interaction-21glitch': '#21-interaction--21-glitch',
  'interaction-21glitch': '#interaction--21-glitch',
};

interface Props extends SxProps {
  type: LogoType;
  fill?: string;
}
const Logo: React.FC<Props> = ({ fill = 'secondary', type, ...restProps }) => {
  return (
    <svg {...restProps}>
      <use sx={{ fill }} xlinkHref={logoMapping[type]}></use>
    </svg>
  );
};

export default Logo;
